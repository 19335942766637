import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/',
        component: () => import('@/views/index'),
        meta: {title: '首页'}
    },
    {
        path: '/video',
        component: () => import('@/views/video'),
        meta: {title: 'canvas'}
    },
    {
        path: '/microcode',
        component: () => import('@/views/microcode/index'),
        meta: {title: 'canvas'}
    },
    {
        path: '/microcode/canvas',
        component: () => import('@/views/microcode/canvas'),
        meta: {title: 'canvas'}
    },
    {
        path: '/microcode/javascript',
        component: () => import('@/views/microcode/javascript'),
        meta: {title: 'javascript'}
    },
    {
        path: '/microcode/vue',
        component: () => import('@/views/microcode/vue'),
        meta: {title: 'vue'}
    },
    {
        path: '/plugin',
        component: () => import('@/views/plugin/index'),
        meta: {title: 'JS插件'}
    },
    {
        path: '/plugin/parabola',
        component: () => import('@/views/plugin/parabola'),
        meta: {title: '抛物线-JS插件'}
    },
    {
        path: '/plugin/select',
        component: () => import('@/views/plugin/select'),
        meta: {title: '下拉选择-JS插件'}
    },
    {
        path: '/plugin/switch',
        component: () => import('@/views/plugin/switch'),
        meta: {title: '开关-JS插件'}
    },
    {
        path: '/plugin/tag',
        component: () => import('@/views/plugin/tag'),
        meta: {title: '标签-JS插件'}
    },
    {
        path: '/plugin/downCount',
        component: () => import('@/views/plugin/downCount'),
        meta: {title: '倒计时-JS插件'}
    },
    {
        path: '/plugin/page',
        component: () => import('@/views/plugin/page'),
        meta: {title: '翻页-JS插件'}
    },
    {
        path: '/plugin/relative',
        component: () => import('@/views/plugin/relative'),
        meta: {title: '关系图谱-JS插件'}
    },
    {
        path: '/plugin/slider',
        component: () => import('@/views/plugin/slider'),
        meta: {title: '滑块-JS插件'}
    },
    {
        path: '/plugin/hashposition',
        component: () => import('@/views/plugin/hashposition'),
        meta: {title: '不重叠位置-JS插件'}
    },
    {
        path: '/plugin/gesturepassword',
        component: () => import('@/views/plugin/gesturepassword'),
        meta: {title: '手势密码-JS插件'}
    },
    {
        path: '/utils',
        component: () => import('@/views/utils/index'),
        meta: {title: '工具类'}
    },
    {
        path: '/utils/createDom',
        component: () => import('@/views/utils/createDom'),
        meta: {title: '创建DOM-工具类'}
    },
    {
        path: '/utils/appendAll',
        component: () => import('@/views/utils/appendAll'),
        meta: {title: '批量Append函數-工具类'}
    },
    {
        path: '/utils/move',
        component: () => import('@/views/utils/move'),
        meta: {title: '缓慢移动-工具类'}
    },
    {
        path: '/utils/eindex',
        component: () => import('@/views/utils/eindex'),
        meta: {title: '获取元素索引-工具类'}
    },
    {
        path: '/utils/inBefore',
        component: () => import('@/views/utils/inBefore'),
        meta: {title: '元素前插入-工具类'}
    },
    {
        path: '/utils/inAfter',
        component: () => import('@/views/utils/inAfter'),
        meta: {title: '元素后插入-工具类'}
    },
    {
        path: '/utils/brothers',
        component: () => import('@/views/utils/brothers'),
        meta: {title: '兄弟节点-工具类'}
    },
    {
        path: '/utils/parents',
        component: () => import('@/views/utils/parents'),
        meta: {title: '父节点-工具类'}
    },
    {
        path: '/utils/toDate',
        component: () => import('@/views/utils/toDate'),
        meta: {title: '时间戳转时间-工具类'}
    },
    {
        path: '/utils/getWeek',
        component: () => import('@/views/utils/getWeek'),
        meta: {title: '获取日期对应周几-工具类'}
    },
    {
        path: '/utils/dateDiff',
        component: () => import('@/views/utils/dateDiff'),
        meta: {title: '两个日期之间差-工具类'}
    },
    {
        path: '/utils/dateAgo',
        component: () => import('@/views/utils/dateAgo'),
        meta: {title: '获取时间对应几天前，多少小时前-工具类'}
    },
     {
        path: '/utils/removeDuplicate',
        component: () => import('@/views/utils/removeDuplicate'),
        meta: {title: '数组去重-工具类'}
    },
    {
        path: '/utils/getRandom',
        component: () => import('@/views/utils/getRandom'),
        meta: {title: '随机获取数组不重复的N条-工具类'}
    },
    {
        path: '/utils/shuffle',
        component: () => import('@/views/utils/shuffle'),
        meta: {title: '数组随机排序-工具类'}
    },
    {
        path: '/utils/dice',
        component: () => import('@/views/utils/dice'),
        meta: {title: '骰子产生-工具类'}
    },
    {
        path: '/utils/getIndex',
        component: () => import('@/views/utils/getIndex'),
        meta: {title: '对象在对象数组的索引-工具类'}
    },
    {
        path: '/utils/repeatNum',
        component: () => import('@/views/utils/repeatNum'),
        meta: {title: '统计数组中元素出现的次数-工具类'}
    },
    {
        path: '/utils/randomArrayObject',
        component: () => import('@/views/utils/randomArrayObject'),
        meta: {title: '随机生成数量为num的不重复数组对象-工具类'}
    },
    {
        path: '/utils/chunk',
        component: () => import('@/views/utils/chunk'),
        meta: {title: '将数组拆分成多个新数组-工具类'}
    },
    {
        path: '/utils/setCookie',
        component: () => import('@/views/utils/setCookie'),
        meta: {title: '创建Cookie-工具类'}
    },
    {
        path: '/utils/getCookie',
        component: () => import('@/views/utils/getCookie'),
        meta: {title: '读取Cookie-工具类'}
    },
    {
        path: '/utils/removeCookie',
        component: () => import('@/views/utils/removeCookie'),
        meta: {title: '删除Cookie-工具类'}
    },
    {
        path: '/utils/setItem',
        component: () => import('@/views/utils/setItem'),
        meta: {title: '创建本地存储-工具类'}
    },
    {
        path: '/utils/getItem',
        component: () => import('@/views/utils/getItem'),
        meta: {title: '读取本地存储-工具类'}
    },
    {
        path: '/utils/removeItem',
        component: () => import('@/views/utils/removeItem'),
        meta: {title: '删除本地存储-工具类'}
    },
    {
        path: '/utils/toRgb',
        component: () => import('@/views/utils/toRgb'),
        meta: {title: '16进制颜色转RGB-工具类'}
    },
    {
        path: '/utils/rgbTo16',
        component: () => import('@/views/utils/rgbTo16'),
        meta: {title: 'RGB颜色转16进制颜色-工具类'}
    },
    {
        path: '/utils/hsvToRgb',
        component: () => import('@/views/utils/hsvToRgb'),
        meta: {title: 'Hsv颜色转RGB-工具类'}
    },
    {
        path: '/utils/rgbToHsv',
        component: () => import('@/views/utils/rgbToHsv'),
        meta: {title: 'RGB颜色转Hsv-工具类'}
    },

    {
        path: '/404',
        component: () => import('@/views/404'),
        meta: {title: '404'}
    },
    {path: '*', redirect: '/404'}
]

const router = new Router({
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active'
})
export default router
