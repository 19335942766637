<template>
  <div class="code" :class="whichClass">
    <header class="navbar">
      <div class="sidebar-button" @click="open=true">
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" viewBox="0 0 448 512" class="icon">
          <path fill="currentColor"
                d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"></path>
        </svg>
      </div>
      <router-link tag="a" to="/"><span class="site-name">Leeao个人微码</span></router-link>
      <nav class="nav-links can-hide">
        <div class="nav-item">
          <router-link to="/microcode" tag="a">微码</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/plugin" tag="a">JS插件</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/utils" tag="a">工具类</router-link>
        </div>
        <div class="nav-item"><a href="https://github.com/cokucoku" target="_blank">
          Github
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" x="0px" y="0px" viewBox="0 0 100 100" width="15"
               height="15" class="icon outbound">
            <path fill="currentColor"
                  d="M18.8,85.1h56l0,0c2.2,0,4-1.8,4-4v-32h-8v28h-48v-48h28v-8h-32l0,0c-2.2,0-4,1.8-4,4v56C14.8,83.3,16.6,85.1,18.8,85.1z"></path>
            <polygon fill="currentColor"
                     points="45.7,48.7 51.3,54.3 77.2,28.5 77.2,37.2 85.2,37.2 85.2,14.9 62.8,14.9 62.8,22.9 71.5,22.9"></polygon>
          </svg>
        </a></div>
      </nav>

    </header>
    <div class="sidebar-mask" @click="open=false"></div>
    <div class="sidebar">
      <nav class="nav-links">
        <div class="nav-item">
          <router-link to="/microcode" tag="a">微码</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/plugin" tag="a">JS插件</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/utils" tag="a">工具类</router-link>
        </div>
        <div class="nav-item"><a href="https://github.com/cokucoku" target="_blank">
          Github
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" x="0px" y="0px" viewBox="0 0 100 100" width="15"
               height="15" class="icon outbound">
            <path fill="currentColor"
                  d="M18.8,85.1h56l0,0c2.2,0,4-1.8,4-4v-32h-8v28h-48v-48h28v-8h-32l0,0c-2.2,0-4,1.8-4,4v56C14.8,83.3,16.6,85.1,18.8,85.1z"></path>
            <polygon fill="currentColor"
                     points="45.7,48.7 51.3,54.3 77.2,28.5 77.2,37.2 85.2,37.2 85.2,14.9 62.8,14.9 62.8,22.9 71.5,22.9"></polygon>
          </svg>
        </a></div>
      </nav>
      <ul class="sidebar-links">
        <li>
          <p class="sidebar-heading"><span>微码</span></p>
          <ul class="sidebar-group-items">
            <li>
              <router-link tag="a" to="/microcode/canvas" class="sidebar-link">canvas</router-link>
            </li>
            <li>
              <router-link tag="a" to="/microcode/javascript" class="sidebar-link">javascript</router-link>
            </li>
            <li>
              <router-link tag="a" to="/microcode/vue" class="sidebar-link">vue</router-link>
            </li>
          </ul>
        </li>
        <li>
          <p class="sidebar-heading"><span>JS插件</span></p>
          <ul class="sidebar-group-items">
            <li>
              <router-link tag="a" to="/plugin/parabola" class="sidebar-link">抛物线<code>parabola</code></router-link>
            </li>
            <li>
              <router-link tag="a" to="/plugin/select" class="sidebar-link">下拉选择<code>select</code></router-link>
            </li>
            <li>
              <router-link tag="a" to="/plugin/switch" class="sidebar-link">开关<code>switch</code></router-link>
            </li>
            <li>
              <router-link tag="a" to="/plugin/tag" class="sidebar-link">标签<code>tag</code></router-link>
            </li>
            <li>
              <router-link tag="a" to="/plugin/downCount" class="sidebar-link">倒计时<code>downCount</code></router-link>
            </li>
            <li>
              <router-link tag="a" to="/plugin/page" class="sidebar-link">翻页<code>page</code></router-link>
            </li>
            <li>
              <router-link tag="a" to="/plugin/relative" class="sidebar-link">关系图谱<code>relative</code></router-link>
            </li>
            <li>
              <router-link tag="a" to="/plugin/slider" class="sidebar-link">滑块<code>slider</code></router-link>
            </li>
            <li>
              <router-link tag="a" to="/plugin/hashPosition" class="sidebar-link">随机不重叠<code>hashPosition</code>
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/plugin/gesturePassword" class="sidebar-link">手势密码<code>gesturePassword</code>
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <p class="sidebar-heading"><span>工具类</span></p>
          <ul class="sidebar-group-items">
            <li>
              <p class="sidebar-heading1">元素</p>
              <ul class="sidebar-group-items1">
                <li>
                  <router-link tag="a" to="/utils/createDom" class="sidebar-link">lee.element.createDom</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/appendAll" class="sidebar-link">lee.element.appendAll</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/move" class="sidebar-link">lee.element.move</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/eindex" class="sidebar-link">lee.element.index</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/inBefore" class="sidebar-link">lee.element.inBefore</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/inAfter" class="sidebar-link">lee.element.inAfter</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/brothers" class="sidebar-link">Node.brothers</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/parents" class="sidebar-link">Node.parents</router-link>
                </li>
              </ul>
            </li>
            <li>
              <p class="sidebar-heading1">时间</p>
              <ul class="sidebar-group-items1">
                <li>
                  <router-link tag="a" to="/utils/toDate" class="sidebar-link">lee.dateCollect.toDate</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/getWeek" class="sidebar-link">lee.dateCollect.getWeek</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/dateDiff" class="sidebar-link">lee.dateCollect.dateDiff</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/dateAgo" class="sidebar-link">lee.dateCollect.dateAgo</router-link>
                </li>
              </ul>
            </li>
            <li>
              <p class="sidebar-heading1">数组</p>
              <ul class="sidebar-group-items1">
                <li>
                  <router-link tag="a" to="/utils/removeDuplicate" class="sidebar-link">lee.array.removeDuplicate
                  </router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/getRandom" class="sidebar-link">lee.array.getRandom</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/shuffle" class="sidebar-link">lee.array.shuffle</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/dice" class="sidebar-link">lee.array.dice</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/getIndex" class="sidebar-link">lee.array.getIndex</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/repeatNum" class="sidebar-link">lee.array.repeatNum</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/randomArrayObject" class="sidebar-link">lee.array.randomArrayObject
                  </router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/chunk" class="sidebar-link"> lee.array.chunk</router-link>
                </li>
              </ul>
            </li>
            <li>
              <p class="sidebar-heading1">Cookie</p>
              <ul class="sidebar-group-items1">
                <li>
                  <router-link tag="a" to="/utils/setCookie" class="sidebar-link">lee.setCookie</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/getCookie" class="sidebar-link">lee.getCookie</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/removeCookie" class="sidebar-link">lee.removeCookie</router-link>
                </li>
              </ul>
            </li>
            <li>
              <p class="sidebar-heading1">本地存储</p>
              <ul class="sidebar-group-items1">
                <li>
                  <router-link tag="a" to="/utils/setItem" class="sidebar-link">lee.setItem</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/getItem" class="sidebar-link">lee.getItem</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/removeItem" class="sidebar-link">lee.removeItem</router-link>
                </li>
              </ul>
            </li>
            <li>
              <p class="sidebar-heading1">颜色</p>
              <ul class="sidebar-group-items1">
                <li>
                  <router-link tag="a" to="/utils/toRgb" class="sidebar-link">lee.color.toRgb</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/rgbTo16" class="sidebar-link">lee.color.rgbTo16</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/hsvToRgb" class="sidebar-link">lee.color.hsvToRgb</router-link>
                </li>
                <li>
                  <router-link tag="a" to="/utils/rgbToHsv" class="sidebar-link">lee.color.rgbToHsv</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="page">
      <router-view></router-view>
    </div>
    <audio src="MELANCHOLY.m4a" autoplay loop preload></audio>
    <transition name="dong">
      <div class="motto" v-if="motto">{{motto}}</div>
    </transition>
    <div class="videoIco"> <router-link tag="a" to="/video"><img src="@/assets/myimg/v.png"></router-link> </div>
  </div>
</template>

<script>
import '@/assets/css/lee.css'
import '@/assets/css/lee-mb.css'

export default {
    name: 'mycode',
    components: {},
    data() {
        return {
            open: false,
            motto: ''
        }
    },
    created() {
        //this.getMotto()
        setInterval(this.getMotto, 10000)

    },
    watch: {
        $route() {
        }
    },
    methods: {
        getMotto() {
            let lee = window.lee
            let myMotto = [
                '乐观主义和悲观主义对世界都有贡献，前者发明了飞机，后者发明了降落伞。',
                '时钟和镜子改变了人类社会，时钟创造了一种焦虑文化，镜子创造了一种自恋文化。',
                '为什么妈妈都不喜欢媳妇，而奶奶都喜欢孙媳妇。。。。因为，敌人的敌人，就是朋友。',
                '在人类行为学课上我们曾学过，朋友失败时，你难过.朋友成功时，你更难过',
                '世上没有陌生人，有的只是还未结识的朋友',
                '认知能力是一个人最核心的竞争力',
                '鸡蛋从外向内打破是食物，从内向外打破是生命。',
                '人生从外向内打破是压力，从内向外打破是成长。',
                '决定我们一生的，有时不是我们的能力，而是我们的选择！',
                '圆规为什么可以画圆？因为脚在走，心不变。你为什么不能圆梦？因为心不定，脚不动。',
                '没规划的人生叫拼图，有规划的人生叫蓝图； 没目标的人生叫流浪，有目标的人生叫航行！',
                '蜜蜂忙碌一天，人见人爱； 蚊子整日奔波，人人喊打！',
                '多么忙不重要，忙什么才重要!',
            ]
            this.motto = lee.array.getRandom(myMotto, 1)[0]
            let st = setTimeout(() => {
                this.motto = ''
                clearTimeout(st)
            }, 9600)


            // fetch('https://v1.hitokoto.cn/?c=f').then((rs) => {
            //     return rs.json()
            // }).then((data) => {
            //     this.motto = data.hitokoto
            //     let st=setTimeout(()=>{
            //         this.motto=''
            //         clearTimeout(st)
            //     },29600)
            // })
        }
    },
    computed: {
        whichClass() {
            if (this.open) {
                return 'open'
            } else {
                return ''
            }
        }
    }
}
</script>
