import Vue from 'vue'
import router  from '@/router'
import App from '@/App.vue'
import '@/permission' // permission control
import '@/utils/lee'
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
Vue.config.productionTip = false
hljs.configure({
  ignoreUnescapedHTML:true
});
//自定义一个代码高亮指令
Vue.directive('highlight',function (el) {
  let highlight = el.querySelectorAll('code');
  highlight.forEach((block)=>{
      hljs.highlightBlock(block)
  })
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
